<template>
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="showText ? '0 0 579.02 142.86' : '0 0 150.02 142.86'"
  >
    <defs></defs>
    <polygon
      style="fill: #fff"
      points="59.55 122.15 75.7 80.25 63.59 82.82 50.82 118.47 51.67 127.09 59.55 122.15"
    />
    <polygon
      style="fill: #fff"
      points="94.2 69.24 134.46 61 134.23 58.6 94.05 61.45 82.61 61.66 94.2 69.24"
    />
    <polygon
      style="fill: #fff"
      points="63.86 48.51 40.25 22.35 26.59 23.82 32.15 30.37 56.63 56.07 67.84 57.06 63.86 48.51"
    />
    <polygon
      style="fill: #fff"
      points="87.14 131.06 113.02 124.74 115.05 130.35 87.83 134.65 84.38 133.62 87.14 131.06"
    />
    <polygon
      style="fill: #fff"
      points="124.62 112.87 135.83 90.9 139.53 91.35 138.85 92.64 129.39 115.86 121.8 116.89 124.62 112.87"
    />
    <polygon
      style="fill: #fff"
      points="41.63 122.67 21.42 104.5 18.24 106.76 19.94 108.94 38.82 128.59 50.43 128.59 41.63 122.67"
    />
    <polygon
      style="fill: #fff"
      points="5.16 56.89 16.94 26.34 25.5 15.32 22.4 30.26 8.99 58.6 5.16 61.66 5.16 56.89"
    />
    <polygon
      style="fill: #fff"
      points="76.49 4.32 38.5 9.44 38.5 17.51 41.03 15.87 77.26 7.85 79.66 6.02 76.49 4.32"
    />
    <circle style="fill: #f37036" cx="74.34" cy="65.74" r="20.17" />
    <circle style="fill: #f37036" cx="27.42" cy="17.51" r="13.71" />
    <circle style="fill: #f37036" cx="5.16" cy="62.04" r="5.16" />
    <circle style="fill: #f37036" cx="121.8" cy="122.69" r="10.22" />
    <circle style="fill: #f37036" cx="138.3" cy="88.2" r="4.44" />
    <circle style="fill: #f37036" cx="83.52" cy="133.62" r="4.44" />
    <circle style="fill: #f37036" cx="18.24" cy="104.5" r="4.44" />
    <circle style="fill: #f37036" cx="81.65" cy="5.16" r="5.16" />
    <circle style="fill: #f37036" cx="142.54" cy="59.09" r="8.3" />
    <circle style="fill: #f37036" cx="50.82" cy="130.67" r="12.19" />
    <path
      v-if="showText"
      style="fill: #f37036"
      d="M229.98,37.97c0,2.36-.31,4.63-.92,6.81s-1.47,4.23-2.58,6.14-2.45,3.65-4.02,5.22-3.3,2.92-5.2,4.04-3.94,1.99-6.14,2.6-4.47,.92-6.83,.92-4.63-.31-6.81-.92-4.23-1.48-6.14-2.6-3.65-2.47-5.22-4.04-2.92-3.31-4.04-5.22-1.99-3.95-2.6-6.14-.92-4.45-.92-6.81,.31-4.63,.92-6.83,1.48-4.24,2.6-6.14,2.47-3.63,4.04-5.2,3.31-2.91,5.22-4.02,3.95-1.97,6.14-2.58,4.45-.92,6.81-.92,4.63,.31,6.83,.92,4.24,1.47,6.14,2.58,3.63,2.45,5.2,4.02,2.91,3.3,4.02,5.2,1.97,3.94,2.58,6.14,.92,4.47,.92,6.83Zm-9.88,0c0-2.17-.42-4.22-1.25-6.15s-1.96-3.6-3.38-5.03-3.1-2.55-5.03-3.38-3.98-1.25-6.15-1.25-4.25,.42-6.17,1.25-3.59,1.96-5.03,3.38-2.56,3.1-3.4,5.03-1.25,3.98-1.25,6.15,.42,4.21,1.25,6.12,1.96,3.58,3.4,5.01,3.11,2.56,5.03,3.4,3.97,1.25,6.17,1.25,4.22-.42,6.15-1.25,3.6-1.96,5.03-3.4,2.55-3.1,3.38-5.01,1.25-3.95,1.25-6.12Z"
    />
    <path
      v-if="showText"
      style="fill: #f37036"
      d="M245.03,76.75h-9.53V25.49h2.29l3.78,4.47c.85-.81,1.79-1.57,2.81-2.29s2.07-1.33,3.17-1.85,2.24-.93,3.41-1.23,2.37-.45,3.57-.45c2.61,0,5.07,.47,7.37,1.4s4.31,2.26,6.03,3.97,3.08,3.77,4.07,6.19,1.49,5.09,1.49,8.02-.5,5.79-1.49,8.23-2.35,4.51-4.07,6.2-3.73,3-6.03,3.9-4.75,1.35-7.37,1.35c-.83,0-1.66-.09-2.48-.28s-1.63-.43-2.43-.75-1.58-.66-2.36-1.06-1.52-.8-2.24-1.21v16.64Zm18.96-33.03c0-1.57-.25-2.98-.75-4.23s-1.17-2.3-2.03-3.17-1.86-1.53-3.02-1.99-2.38-.69-3.67-.69-2.51,.23-3.66,.69-2.14,1.13-3,1.99-1.53,1.92-2.03,3.17-.75,2.66-.75,4.23,.25,2.88,.75,4.12,1.17,2.31,2.03,3.19,1.85,1.56,3,2.05,2.36,.73,3.66,.73,2.52-.24,3.67-.73,2.16-1.17,3.02-2.05,1.53-1.94,2.03-3.19,.75-2.62,.75-4.12Z"
    />
    <path
      v-if="showText"
      style="fill: #f37036"
      d="M297.05,62.61c-2.29,0-4.44-.43-6.45-1.3s-3.77-2.06-5.29-3.57-2.7-3.28-3.57-5.29-1.3-4.16-1.3-6.45v-11.02h-4.61v-9.43h4.61V10.72h9.43v14.83h14.35v9.43h-14.35v11.02c0,.99,.18,1.92,.55,2.79s.88,1.62,1.53,2.27,1.41,1.16,2.29,1.54,1.81,.57,2.81,.57h7.17v9.43h-7.17Z"
    />
    <path
      v-if="showText"
      style="fill: #f37036"
      d="M320.69,15.4c0,.88-.17,1.7-.5,2.46s-.79,1.43-1.37,1.99-1.25,1.02-2.03,1.35-1.6,.5-2.48,.5-1.7-.17-2.48-.5-1.44-.79-2.01-1.35-1.02-1.23-1.35-1.99-.5-1.58-.5-2.46,.17-1.67,.5-2.44,.79-1.44,1.35-2.01,1.24-1.02,2.01-1.35,1.6-.5,2.48-.5,1.7,.17,2.48,.5,1.45,.79,2.03,1.35,1.03,1.24,1.37,2.01,.5,1.59,.5,2.44Zm-1.63,47.21h-9.53V25.49h9.53V62.61Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M337.22,62.61h-9.95V12.91h34.8v9.95h-24.85v9.95h14.9v9.95h-14.9v19.86Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M397.71,43.72c0,2.77-.5,5.36-1.49,7.75s-2.35,4.46-4.06,6.2-3.71,3.12-6.01,4.12-4.77,1.51-7.4,1.51-5.07-.5-7.37-1.51-4.31-2.38-6.03-4.12-3.08-3.81-4.07-6.2-1.49-4.97-1.49-7.75,.5-5.43,1.49-7.83,2.35-4.48,4.07-6.22,3.73-3.11,6.03-4.11,4.75-1.49,7.37-1.49,5.1,.47,7.4,1.4,4.3,2.26,6.01,3.99,3.06,3.79,4.06,6.2,1.49,5.1,1.49,8.06Zm-9.53,0c0-1.53-.25-2.91-.75-4.14s-1.17-2.3-2.03-3.19-1.85-1.57-3-2.05-2.36-.71-3.66-.71-2.51,.24-3.66,.71-2.14,1.16-2.98,2.05-1.51,1.95-1.99,3.19-.73,2.62-.73,4.14c0,1.43,.24,2.76,.73,3.99s1.15,2.29,1.99,3.19,1.84,1.61,2.98,2.13,2.36,.78,3.66,.78,2.51-.24,3.66-.73,2.14-1.17,3-2.05,1.53-1.94,2.03-3.19,.75-2.62,.75-4.12Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M412.4,62.61h-9.46V25.49h2.29l3.12,4.4c1.53-1.39,3.26-2.46,5.2-3.21s3.95-1.13,6.03-1.13h8.35v9.43h-8.35c-.99,0-1.93,.18-2.81,.55s-1.64,.88-2.29,1.53-1.16,1.41-1.53,2.29-.55,1.81-.55,2.81v20.45Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M479.54,62.61h-9.43v-23.57c0-.65-.12-1.26-.36-1.84s-.58-1.09-1.02-1.53-.95-.78-1.53-1.02-1.19-.36-1.84-.36-1.25,.12-1.82,.36-1.06,.58-1.49,1.02-.76,.95-1.01,1.53-.36,1.19-.36,1.84v23.57h-9.46v-23.57c0-.65-.12-1.26-.36-1.84s-.58-1.09-1.02-1.53-.95-.78-1.53-1.02-1.19-.36-1.84-.36-1.25,.12-1.82,.36-1.06,.58-1.49,1.02-.76,.95-1.01,1.53-.36,1.19-.36,1.84v23.57h-9.46V25.49h2.29l2.56,2.84c1.32-1.11,2.77-1.97,4.35-2.58s3.23-.92,4.94-.92,3.43,.32,5.08,.97,3.1,1.77,4.35,3.36c.58-.79,1.24-1.46,1.98-2.01s1.52-1.01,2.34-1.35,1.67-.6,2.55-.75,1.74-.23,2.6-.23c1.96,0,3.81,.37,5.53,1.11s3.22,1.75,4.51,3.03,2.29,2.79,3.03,4.52,1.11,3.58,1.11,5.55v23.57Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M513.72,58.28c-.76,.69-1.58,1.35-2.44,1.96s-1.77,1.14-2.72,1.59-1.92,.81-2.93,1.07-2.03,.4-3.07,.4c-2.29,0-4.44-.4-6.45-1.21s-3.77-1.96-5.29-3.45-2.7-3.3-3.57-5.44-1.3-4.53-1.3-7.19V25.49h9.43v20.52c0,1.25,.19,2.36,.57,3.34s.9,1.81,1.54,2.48,1.4,1.18,2.27,1.53,1.8,.52,2.79,.52,1.89-.23,2.76-.68,1.62-1.05,2.27-1.79,1.16-1.58,1.53-2.51,.55-1.9,.55-2.89V25.49h9.46V62.61h-2.29l-3.12-4.33Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M535.87,62.61h-9.53V10.72h9.53V62.61Z"
    />
    <path
      v-if="showText"
      style="fill: #fff"
      d="M579.02,62.61h-2.29l-3.67-5.1c-.9,.81-1.85,1.57-2.86,2.27s-2.06,1.32-3.17,1.84-2.25,.93-3.41,1.23-2.35,.45-3.55,.45c-2.61,0-5.07-.44-7.37-1.32s-4.31-2.15-6.03-3.83-3.08-3.73-4.07-6.15-1.49-5.19-1.49-8.28c0-2.89,.5-5.54,1.49-7.95s2.35-4.49,4.07-6.22,3.73-3.08,6.03-4.04,4.75-1.44,7.37-1.44c1.2,0,2.39,.15,3.57,.45s2.32,.72,3.43,1.25,2.17,1.16,3.17,1.87,1.95,1.49,2.82,2.32l3.67-4.4h2.29V62.61Zm-9.53-18.89c0-1.29-.25-2.55-.75-3.76s-1.17-2.29-2.03-3.22-1.85-1.69-3-2.25-2.36-.85-3.66-.85-2.51,.22-3.66,.66-2.14,1.09-2.98,1.94-1.51,1.91-1.99,3.17-.73,2.7-.73,4.32,.24,3.06,.73,4.32,1.15,2.32,1.99,3.17,1.84,1.5,2.98,1.94,2.36,.66,3.66,.66,2.51-.28,3.66-.85,2.14-1.32,3-2.25,1.53-2.01,2.03-3.22,.75-2.47,.75-3.76Z"
    />
    <g v-if="showText">
      <path
        style="fill: #fff"
        d="M189.32,128.7h-8.41v-42.01h16.82c1.54,0,3.03,.2,4.45,.6s2.76,.97,4,1.7,2.37,1.61,3.4,2.64,1.9,2.16,2.64,3.41,1.3,2.59,1.7,4.01,.6,2.91,.6,4.45c0,2.3-.44,4.48-1.32,6.52s-2.08,3.82-3.6,5.35-3.31,2.72-5.35,3.6-4.21,1.32-6.52,1.32h-8.41v8.41Zm0-33.6v16.82h8.41c1.15,0,2.24-.22,3.25-.66s1.9-1.04,2.67-1.8,1.36-1.66,1.8-2.68,.66-2.11,.66-3.27-.22-2.24-.66-3.27-1.04-1.92-1.8-2.68-1.65-1.36-2.67-1.8-2.1-.66-3.25-.66h-8.41Z"
      />
      <path
        style="fill: #fff"
        d="M226.59,128.7h-8v-31.38h1.93l2.64,3.72c1.29-1.17,2.75-2.08,4.39-2.71s3.34-.95,5.1-.95h7.06v7.97h-7.06c-.84,0-1.63,.16-2.37,.47s-1.39,.74-1.93,1.29-.98,1.19-1.29,1.93-.47,1.53-.47,2.37v17.29Z"
      />
      <path
        style="fill: #fff"
        d="M273.17,112.74c0,2.34-.42,4.53-1.26,6.55s-1.98,3.77-3.43,5.24-3.14,2.64-5.08,3.49-4.03,1.27-6.25,1.27-4.28-.42-6.23-1.27-3.64-2.01-5.1-3.49-2.6-3.22-3.44-5.24-1.26-4.2-1.26-6.55,.42-4.59,1.26-6.62,1.99-3.78,3.44-5.26,3.15-2.63,5.1-3.47,4.02-1.26,6.23-1.26,4.31,.4,6.25,1.19,3.64,1.91,5.08,3.37,2.59,3.2,3.43,5.24,1.26,4.31,1.26,6.81Zm-8.06,0c0-1.29-.21-2.46-.63-3.5s-.99-1.94-1.71-2.7-1.57-1.33-2.53-1.73-2-.6-3.09-.6-2.12,.2-3.09,.6-1.81,.98-2.52,1.73-1.27,1.65-1.68,2.7-.62,2.21-.62,3.5c0,1.21,.21,2.33,.62,3.37s.97,1.93,1.68,2.7,1.55,1.36,2.52,1.8,2,.66,3.09,.66,2.12-.21,3.09-.62,1.81-.99,2.53-1.73,1.29-1.64,1.71-2.7,.63-2.22,.63-3.49Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
